body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.site-layout-background {
    background-color: white;
}

.sider-rm {
    height: 100vh;
    position: fixed;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
    background-color: white;
}

.site-layout-rm {
    margin-left: 200px;
}

.header-rm {
    position: fixed;
    z-index: 1;
    background: linear-gradient(87deg, #00A8E0, #00769D);
    width: calc(100vw - 200px);
    padding: 0px;
}

.content-layout-rm {
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
    margin: 24px;
    padding: 24px;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0.375rem;
    height: calc(100vh - 70px - 64px - 48px);
    margin-top: calc(24px + 64px);
    overflow: scroll;
}

.footer-rm {
    position: fixed;
    z-index: 1;
    margin-top: calc(100vh - 70px);
    width: calc(100vw - 200px);
    padding-right: 24px !important;
    padding-left: 24px !important;
    padding-top: 5px;
    padding-bottom: 5px;
}

.header-menu-rm {
    float: right;
    background-color: transparent;
    color: white;
}

@media only screen and (max-width: 768px) {
    .site-layout-rm {
        margin-left: 0px;
    }
    .header-rm {
        width: calc(100vw);
    }
    .footer-rm {
        width: calc(100vw);
    }
}

.header-menu-rm>.ant-menu-submenu:hover {
    color: #bebebe !important;
}

.header-menu-rm>.ant-menu-submenu .ant-menu-submenu-title:hover {
    color: #bebebe !important;
}

.loading-rm {
    height: calc(100vh - 70px - 64px - 48px) !important;
    margin-top: calc(24px + 64px);
    position: fixed !important;
    width: calc(100vw - 200px);
    z-index: 100;
}

.fixed-widgets {
    position: fixed;
    right: 32px;
    bottom: 32px;
    z-index: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    cursor: pointer;
}

.logo-box-rm {
    max-height: 64px;
    padding: 15px;
}

.logo-rm {
    width: 100%;
    height: auto;
}

.login-rm {
    background-image: url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg);
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
    height: calc(100vh - 64px);
}

.container {
    max-width: 1280px;
    margin-right: calc((100vw - 1280px)/2);
    margin-left: calc((100vw - 1280px)/2);
}

.last-item {
    margin-bottom: 0px !important;
}

.page-header-rm {
    padding: 0px !important;
    padding-bottom: 24px !important;
}

.virtual-table .ant-table-container:before,
.virtual-table .ant-table-container:after {
    display: none;
}

.virtual-table-cell {
    box-sizing: border-box;
    padding: 16px;
    border-bottom: 1px solid #e8e8e8;
    background: #FFF;
}

[data-theme="dark"] .virtual-table-cell {
    box-sizing: border-box;
    padding: 16px;
    border-bottom: 1px solid #303030;
    background: #141414;
}

div.timeline-card>div.ant-card-body {
    overflow-x: scroll !important;
}

.ant-input-number {
    width: 100% !important;
}

.ant-picker {
    width: 100% !important;
}

.ant-card-head-title {
    font-weight: bold !important;
}